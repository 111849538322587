import request from "./request";

export function getRepayments(page, perPage, filters) {
    let url = `dashboard/repayments?page=${page}&perPage=${perPage}`;
    Object.keys(filters).forEach(key => {
        if (filters[key] !== null) {
            url += `&filter[${key}]=${filters[key]}`;
        }
    });
    return request({
        url,
        method: "get",
    });
}

export function getRepayment(id) {
    return request({
        url: "dashboard/repayments/" + id,
        method: "get",
    });
}

export function getRepaymentStat() {
    return request({
        url: "dashboard/repayments/stats",
        method: "get",
    });
}

export function validateRepayment(id, data) {
    return request({
        url: "dashboard/repayments/" + id,
        method: "post",
        data
    });
}
