<script setup>
/* eslint-disable no-unused-vars */
import { onMounted, ref, reactive } from "vue";
import { ElNotification } from "element-plus";
import {
  getRepayments,
  validateRepayment,
  getRepaymentStat,
} from "@/http/repayments";
import { Search } from "@element-plus/icons-vue";

const activeName = ref("pending");

const openDialog = ref(false);
const stats = ref(null);
const loading = ref(false);
const tableData = ref([]);
const transaction_id = ref(null);
const repayment_id = ref(null);
const paginationData = reactive({
  lastPage: "",
  from: "",
  to: "",
  total: "",
  page: 1,
  perPage: 10,
  currentPage: "",
});

const handleValidateRepayment = async () => {
  loading.value = true;
  await validateRepayment(repayment_id.value, {
    transaction_id: transaction_id.value,
  })
    .then(() => {
      ElNotification({
        message: "Reversement validé !",
        type: "success",
      });
      toggleValidate();
      fetchData();
    })
    .catch(() => {
      ElNotification({
        message: "Erreur de reversement validation !",
        type: "error",
      });
    });
  loading.value = false;
};

const resetPaginationData = () => {
  paginationData.lastPage = "";
  paginationData.from = "";
  paginationData.to = "";
  paginationData.total = "";
  paginationData.page = 1;
  paginationData.perPage = 10;
  paginationData.currentPage = "";
};

const requestQuery = reactive({
  min_amount: null,
  max_amount: null,
  done: 0,
  reason: null,
});

onMounted(() => {
  fetchData();
});

const fetchData = async () => {
  try {
    loading.value = true;
    const response = await getRepayments(
      paginationData.page,
      paginationData.perPage,
      requestQuery
    );
    tableData.value = response.data;
    paginationData.lastPage = response.last_page;
    paginationData.from = response.from;
    paginationData.to = response.to;
    paginationData.total = response.total;
    paginationData.currentPage = response.current_page;
    await getRepaymentStat().then((res) => {
      stats.value = res;
    });
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Oops. Les reversements n'ont pas pu être chargés",
      type: "error",
    });
  } finally {
    loading.value = false;
  }
};

const handleTabClick = (tab) => {
  if (tab.props.name == "done") {
    requestQuery.done = 1;
  } else if (tab.props.name == "pending") {
    requestQuery.done = 0;
  }
  fetchData();
};

const toggleValidate = (id = null) => {
  openDialog.value = !openDialog.value;
  transaction_id.value = null;
  repayment_id.value = id;
};
</script>

<template>
  <!-- Breadcrumb -->
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-uppercase">
          <a href="#">Findlove</a>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">
          Reversements ({{ stats?.done + stats?.pending }})
        </li>
      </ol>
    </nav>
    <!--  <el-input
      v-model="requestQuery.reason"
      @change="fetchData"
      class="w-50 m-2"
      placeholder="Rechercher..."
      :prefix-icon="Search"
      size="large"
    /> -->
  </div>
  <hr class="mb-5" />
  <!-- End Breadcrumb -->
  <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleTabClick">
    <el-tab-pane :label="'En attente(' + stats?.pending + ')'" name="pending">
    </el-tab-pane>
    <el-tab-pane :label="'Validés(' + stats?.done + ')'" name="done">
    </el-tab-pane>
  </el-tabs>
  <div class="card py-3 border-0 table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Nom du compte</th>
          <th scope="col">Numéro MM</th>
          <th scope="col">Montant</th>
          <th v-if="requestQuery.done" scope="col">ID Transaction</th>
          <th scope="col">Description</th>
          <th v-if="!requestQuery.done" scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(transaction, index) in tableData" :key="index">
          <tr>
            <td>
              <div>{{ transaction?.user?.name }}</div>
              <div
                v-if="transaction?.user?.certified"
                class="badge rounded-pill text-white bg-success"
              >
                Certifié
              </div>
            </td>
            <td>{{ transaction?.user?.phone }}</td>
            <td>{{ transaction?.amount }} CFA</td>
            <td v-if="requestQuery.done">
              {{ transaction?.transaction_id }}
              <p>validé par admin{{ transaction?.user?.manager?.name }}</p>
            </td>
            <td>
              <div>
                <div>{{ transaction?.reason }}</div>
                <div>
                  {{
                    transaction?.created_at &&
                    new Date(transaction?.created_at)
                      .toISOString()
                      .split("T")[0]
                  }}
                </div>
              </div>
            </td>
            <td v-if="!requestQuery.done">
              <el-button
                @click="toggleValidate(transaction?.id)"
                :disabled="!transaction?.user?.repayment_mean"
                class="m-2"
                type="success"
                size="small"
              >
                valider
              </el-button>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <Loader :loading="loading" />
    <div class="d-flex justify-content-between align-items-center m-3">
      <ul class="pagination pointer">
        <li>
          <p
            @click="
              () => {
                if (paginationData.page > 1) {
                  paginationData.page--;
                  fetchData();
                }
              }
            "
            aria-label="Previous"
          >
            <span aria-hidden="true">«</span>
          </p>
        </li>
        <template v-for="(page, index) in paginationData.lastPage" :key="index">
          <li :class="{ active: page === paginationData.currentPage }">
            <p
              @click="
                page !== paginationData.currentPage &&
                  ((paginationData.page = page), fetchData())
              "
            >
              {{ page }}
            </p>
          </li>
        </template>
        <li>
          <p
            @click="
              () => {
                if (paginationData.page < lastPage) {
                  paginationData.page++;
                  fetchData();
                }
              }
            "
            aria-label="Next"
          >
            <span aria-hidden="true">»</span>
          </p>
        </li>
      </ul>
      <div class="">
        <em
          >Affichage de {{ paginationData.from }} à {{ paginationData.to }} (sur
          {{ paginationData.total }} éléments)</em
        >
      </div>
    </div>
  </div>

  <el-dialog
    v-model="openDialog"
    title="Validation Transaction"
    center
    :destroy-on-close="true"
  >
    <label>ID Transaction</label>
    <input type="text" v-model="transaction_id" class="form-control" />
    <template #footer>
      <span class="dialog-footer">
        <Loader v-if="loading" :loading="loading" />
        <el-button v-else type="primary" @click="handleValidateRepayment">
          Valider
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
}

.label {
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 5px;
  background: rgba(38, 150, 190, 0.2);
  color: rgb(38, 150, 190);
}
</style>
